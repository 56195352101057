<div class="h-screen w-screen" [class.isDarkTheme]="isDarkMode">
    <div
        class="h-full w-full flex flex-row place-content-center items-center bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-50"
    >
        <div class="flex flex-col lg:flex-row items-center">
            <div
                class="my-4 mx-8 text-[150px] font-[cursive] font-bold text-sky-400"
            >
                <div class="tracking-widest">:(</div>
            </div>
            <div class="flex flex-col items-center lg:items-start gap-4">
                <div class="text-2xl font-bold">{{ title | translate }}</div>
                <div>{{ text | translate }}</div>
                <button
                    mat-flat-button
                    class="!bg-orange-500 !text-white"
                    (click)="goHome()"
                >
                    {{ 'bad_route.go_home' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
